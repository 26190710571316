"use client"

import type React from "react"

import { useState, useCallback } from "react"
import { Input } from "@/components/ui/input"
import { Button } from "@/components/ui/button"
import { InfoIcon as InfoCircle, Loader2 } from "lucide-react"
import { useTranslation } from "@/hooks/use-translation"
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip"
import { useToast } from "@/components/ui/use-toast"
import type { SearchFormProps } from "@/types"
import { trackEvent } from "@/lib/analytics"

export function SearchForm({ onSearch, loading = false }: SearchFormProps) {
  const [discordId, setDiscordId] = useState("")
  const { t } = useTranslation()
  const { toast } = useToast()

  const handleSubmit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault()

      // Validate input
      if (discordId.trim()) {
        if (!/^\d+$/.test(discordId)) {
          toast({
            title: t("validation.error"),
            description: t("id.numbers.only"),
            variant: "destructive",
          })
          return
        }

        if (discordId.length < 15) {
          toast({
            title: t("validation.error"),
            description: t("id.too.short"),
            variant: "destructive",
          })
          return
        }

        if (discordId.length > 22) {
          toast({
            title: t("validation.error"),
            description: t("id.too.long"),
            variant: "destructive",
          })
          return
        }

        // Track search event
        trackEvent("search_initiated", {
          discord_id_length: discordId.length,
        })

        onSearch(discordId.trim())
      }
    },
    [discordId, onSearch, toast, t],
  )

  const handleInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    // Only allow numbers
    const value = e.target.value.replace(/\D/g, "")
    setDiscordId(value)
  }, [])

  return (
    <form onSubmit={handleSubmit} className="space-y-3 sm:space-y-4">
      <div className="flex items-center gap-2">
        <h2 className="text-sm sm:text-md font-medium">{t("discord.id")}</h2>
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <InfoCircle className="h-4 w-4 text-blue-500 cursor-help" />
            </TooltipTrigger>
            <TooltipContent className="max-w-xs text-xs sm:text-sm">
              <p>
                {t("tooltip.discord.id")}{" "}
                <a
                  href="https://support.discord.com/hc/en-us/articles/206346498-Where-can-I-find-my-User-Server-Message-ID"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-500 underline"
                >
                  {t("tooltip.learn.how")}
                </a>
              </p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </div>

      <Input
        type="text"
        inputMode="numeric"
        pattern="[0-9]*"
        placeholder={t("enter.discord.id")}
        value={discordId}
        onChange={handleInputChange}
        className="w-full text-sm sm:text-base"
        maxLength={22}
      />

      <Button
        type="submit"
        className="w-full bg-indigo-400 hover:bg-indigo-500 text-sm sm:text-base"
        disabled={loading || !discordId.trim() || discordId.length < 15}
      >
        {loading ? (
          <>
            <Loader2 className="mr-2 h-3 w-3 sm:h-4 sm:w-4 animate-spin" />
            {t("searching")}
          </>
        ) : (
          t("search")
        )}
      </Button>
    </form>
  )
}

