"use client";

import { useState, useEffect, useCallback } from "react";
import { useRouter } from "next/navigation";
import { LanguageSelector } from "@/components/language-selector";
import { SearchForm } from "@/components/search-form";
import { RecentSearches } from "@/components/recent-searches";
import { FavoritesList } from "@/components/favorites-list";
import { Footer } from "@/components/footer";
import { useTranslation } from "@/hooks/use-translation";
import { CookieConsent } from "@/components/cookie-consent";
import { BackgroundPattern } from "@/components/ui/background-pattern";
import { EnvironmentBanner } from "@/components/environment-banner";
import { getCookieConsent, setCookieConsent } from "@/lib/storage";
import { initializeGA4, trackPageView } from "@/lib/analytics";

export default function Home() {
  const router = useRouter();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [showCookieConsent, setShowCookieConsent] = useState(false);
  const [cookiesEnabled, setCookiesEnabled] = useState(true);

  useEffect(() => {
    // Check if cookies are enabled in the browser
    const checkCookiesEnabled = () => {
      try {
        document.cookie = "test=1";
        const enabled = document.cookie.indexOf("test=") !== -1;
        document.cookie = "test=1; expires=Thu, 01-Jan-1970 00:00:00 GMT";
        setCookiesEnabled(enabled);
      } catch (e) {
        setCookiesEnabled(false);
      }
    };

    checkCookiesEnabled();

    // Check if user has already consented to cookies
    const hasConsented = getCookieConsent();
    if (!hasConsented) {
      setShowCookieConsent(true);
    } else if (hasConsented === "accepted" && cookiesEnabled) {
      // Initialize GA4 if consent was given and cookies are enabled
      initializeGA4();
      trackPageView("/");
    }

    // Prefetch common user IDs for faster navigation
    const recentSearches = JSON.parse(
      localStorage.getItem("recentSearches") || "[]"
    );
    recentSearches.forEach((id: string) => {
      router.prefetch(`/users/${id}`);
    });
  }, [router, cookiesEnabled]);

  const handleSearch = useCallback(
    (id: string) => {
      setLoading(true);
      router.push(`/users/${id}`);
    },
    [router]
  );

  const handleCookieConsent = useCallback(
    (consent: boolean) => {
      setCookieConsent(consent);
      setShowCookieConsent(false);

      if (consent && cookiesEnabled) {
        initializeGA4();
        trackPageView("/");
      }
    },
    [cookiesEnabled]
  );

  return (
    <div className="flex flex-col min-h-screen">
      <EnvironmentBanner />

      <BackgroundPattern className="flex-1 bg-gray-50">
        <main className="flex flex-col items-center py-4 sm:py-6 md:py-8 px-3 sm:px-4">
          <div className="w-full max-w-md flex justify-end mb-4">
            <LanguageSelector />
          </div>

          <div className="w-full max-w-md">
            <div className="bg-white rounded-lg shadow-sm p-4 sm:p-6 mb-4">
              <SearchForm onSearch={handleSearch} loading={loading} />
            </div>

            <div className="bg-white rounded-lg shadow-sm p-4 sm:p-6 mb-4">
              <FavoritesList onSelect={handleSearch} />
            </div>

            <div className="bg-white rounded-lg shadow-sm p-4 sm:p-6">
              <RecentSearches onSelect={handleSearch} />
            </div>
          </div>

          <div className="mt-auto pt-6 sm:pt-8">
            <Footer />
          </div>

          {showCookieConsent && (
            <CookieConsent onConsent={handleCookieConsent} />
          )}
        </main>
      </BackgroundPattern>
    </div>
  );
}
