"use client";

import type React from "react";

import { useState, useEffect, useCallback, memo } from "react";
import { Button } from "@/components/ui/button";
import { HistoryIcon, Trash2 } from "lucide-react";
import { useTranslation } from "@/hooks/use-translation";
import type { RecentSearchesProps } from "@/types";
import { getRecentSearches } from "@/lib/storage";
import { trackEvent } from "@/lib/analytics";

const SearchItem = memo(function SearchItem({
  id,
  onSelect,
  onDelete,
}: {
  id: string;
  onSelect: (id: string) => void;
  onDelete: (id: string, e: React.MouseEvent) => void;
}) {
  return (
    <div className="flex items-center group">
      <Button
        variant="ghost"
        className="w-full justify-start text-left font-mono pr-2 text-xs sm:text-sm group-hover:bg-gray-100"
        onClick={() => onSelect(id)}
      >
        {id}
      </Button>
      <Button
        variant="ghost"
        size="sm"
        className="h-7 sm:h-8 px-1 sm:px-2 text-gray-400 hover:text-red-500 opacity-0 group-hover:opacity-100 transition-opacity"
        onClick={(e) => onDelete(id, e)}
      >
        <Trash2 className="h-3 w-3 sm:h-4 sm:w-4" />
        <span className="sr-only">Delete</span>
      </Button>
    </div>
  );
});

export function RecentSearches({ onSelect }: RecentSearchesProps) {
  const [recentSearches, setRecentSearches] = useState<string[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    setRecentSearches(getRecentSearches());

    // Listen for changes to localStorage
    const handleStorageChange = () => {
      setRecentSearches(getRecentSearches());
    };

    window.addEventListener("storage", handleStorageChange);
    return () => window.removeEventListener("storage", handleStorageChange);
  }, []);

  const handleDelete = useCallback(
    (id: string, e: React.MouseEvent) => {
      e.stopPropagation();
      const updatedSearches = recentSearches.filter(
        (searchId) => searchId !== id
      );
      localStorage.setItem("recentSearches", JSON.stringify(updatedSearches));
      setRecentSearches(updatedSearches);

      // Track event
      trackEvent("recent_search_deleted", { search_id: id });
    },
    [recentSearches]
  );

  const clearAllSearches = useCallback(() => {
    localStorage.setItem("recentSearches", JSON.stringify([]));
    setRecentSearches([]);

    // Track event
    trackEvent("all_recent_searches_cleared");
  }, []);

  if (recentSearches.length === 0) {
    return (
      <div className="flex items-center gap-2 text-gray-500">
        <HistoryIcon className="h-4 w-4" />
        <span>{t("no.recent.searches")}</span>
      </div>
    );
  }

  return (
    <div className="space-y-2 sm:space-y-3">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-2 text-gray-700">
          <HistoryIcon className="h-4 w-4" />
          <h3 className="font-medium">{t("recent.searches")}</h3>
        </div>
        {recentSearches.length > 0 && (
          <Button
            variant="ghost"
            size="sm"
            className="h-7 sm:h-8 px-1 sm:px-2 text-gray-500 hover:text-red-500"
            onClick={clearAllSearches}
          >
            <Trash2 className="h-3 w-3 sm:h-4 sm:w-4" />
            <span className="sr-only">Clear all</span>
          </Button>
        )}
      </div>

      <div className="space-y-1 max-h-[100px] overflow-y-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent">
        {recentSearches.map((id) => (
          <SearchItem
            key={id}
            id={id}
            onSelect={onSelect}
            onDelete={handleDelete}
          />
        ))}
      </div>
    </div>
  );
}
