"use client";

import type React from "react";

import { useState, useEffect, useCallback, memo } from "react";
import { Button } from "@/components/ui/button";
import { Star, Trash2 } from "lucide-react";
import { useTranslation } from "@/hooks/use-translation";
import Image from "next/image";
import { useToast } from "@/components/ui/use-toast";
import type { FavoritesListProps, FavoriteUser } from "@/types";
import { getFavoriteUsers, removeFavoriteUser } from "@/lib/storage";
import { trackEvent } from "@/lib/analytics";

const FavoriteItem = memo(function FavoriteItem({
  user,
  onSelect,
  onDelete,
}: {
  user: FavoriteUser;
  onSelect: (id: string) => void;
  onDelete: (id: string, e: React.MouseEvent) => void;
}) {
  return (
    <div className="flex items-center group hover:bg-gray-50 rounded-md">
      <Button
        variant="ghost"
        className="w-full justify-start text-left py-1 sm:py-2 px-2 sm:px-3"
        onClick={() => onSelect(user.id)}
      >
        <div className="flex items-center gap-2 sm:gap-3">
          <div className="relative w-6 h-6 sm:w-8 sm:h-8 rounded-full overflow-hidden flex-shrink-0">
            <Image
              src={user.avatar || "/placeholder.svg"}
              alt={user.username}
              fill
              sizes="(max-width: 640px) 24px, 32px"
              className="object-cover"
            />
          </div>
          <div>
            <div className="font-medium text-sm sm:text-base">
              {user.username}
            </div>
            {user.displayName && (
              <div className="text-xs text-gray-500">{user.displayName}</div>
            )}
          </div>
        </div>
      </Button>
      <Button
        variant="ghost"
        size="sm"
        className="h-7 sm:h-8 px-1 sm:px-2 text-gray-400 hover:text-red-500 opacity-0 group-hover:opacity-100 transition-opacity"
        onClick={(e) => onDelete(user.id, e)}
      >
        <Trash2 className="h-3 w-3 sm:h-4 sm:w-4" />
        <span className="sr-only">Delete</span>
      </Button>
    </div>
  );
});

export function FavoritesList({ onSelect }: FavoritesListProps) {
  const [favorites, setFavorites] = useState<FavoriteUser[]>([]);
  const { t } = useTranslation();
  const { toast } = useToast();

  useEffect(() => {
    setFavorites(getFavoriteUsers());

    // Listen for changes to localStorage
    const handleStorageChange = () => {
      setFavorites(getFavoriteUsers());
    };

    window.addEventListener("storage", handleStorageChange);
    return () => window.removeEventListener("storage", handleStorageChange);
  }, []);

  const handleDelete = useCallback(
    (id: string, e: React.MouseEvent) => {
      e.stopPropagation();
      removeFavoriteUser(id);
      setFavorites(getFavoriteUsers());

      toast({
        title: t("favorite.removed"),
        description: t("user.removed.from.favorites"),
        duration: 2000,
      });

      // Track event
      trackEvent("favorite_removed", { user_id: id });
    },
    [toast, t]
  );

  if (favorites.length === 0) {
    return (
      <div className="flex items-center gap-2 text-gray-500">
        <Star className="h-4 w-4" />
        <span>{t("no.favorites")}</span>
      </div>
    );
  }

  return (
    <div className="space-y-1">
      <div className="flex items-center gap-2 text-gray-700">
        <Star className="h-4 w-4 fill-yellow-400 text-yellow-400" />
        <h3 className="font-medium">{t("favorites")}</h3>
      </div>

      <div className="space-y-1 sm:space-y-2 max-h-[200px] overflow-y-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent">
        {favorites.map((user) => (
          <FavoriteItem
            key={user.id}
            user={user}
            onSelect={onSelect}
            onDelete={handleDelete}
          />
        ))}
      </div>
    </div>
  );
}
