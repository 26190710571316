"use client"

import { Button } from "@/components/ui/button"
import { useTranslation } from "@/hooks/use-translation"
import type { CookieConsentProps } from "@/types"

export function CookieConsent({ onConsent }: CookieConsentProps) {
  const { t } = useTranslation()

  return (
    <div className="fixed bottom-0 left-0 right-0 bg-white shadow-lg p-3 sm:p-4 z-50">
      <div className="container mx-auto max-w-4xl">
        <div className="flex flex-col sm:flex-row items-center justify-between gap-3 sm:gap-4">
          <div>
            <h3 className="font-bold text-base sm:text-lg">{t("cookie.consent.title")}</h3>
            <p className="text-xs sm:text-sm text-gray-600 mt-1">{t("cookie.consent.description")}</p>
          </div>
          <div className="flex gap-2 w-full sm:w-auto">
            <Button variant="outline" className="flex-1 sm:flex-initial" onClick={() => onConsent(false)}>
              {t("cookie.decline")}
            </Button>
            <Button className="flex-1 sm:flex-initial" onClick={() => onConsent(true)}>
              {t("cookie.accept")}
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

